<script setup lang="ts">
import type {PropType} from "vue";
import type {Elementor} from "~/integration/wordpress/types";
const {$config} = useNuxtApp();

const ElementorElement = defineAsyncComponent(() => import('./ElementorElement.vue'));

const props = defineProps({
  elementor: {
    type: Object  as PropType<Elementor>,
    default: {},
    required: true
  }
})

useHead({
  link: [
    {
      rel: 'stylesheet',
      href: `${$config.public.wpContentUploads}elementor/css/post-${props.elementor.settings.post.id}.css`,
      id: `elementor-post-${props.elementor.settings.post.id}-css`,
      hid: `elementor-post-${props.elementor.settings.post.id}-css`
    }
  ]
});
</script>

<template>
  <div class="elementor-content" v-if="elementor">
    <div data-elementor-type="wp-page" :data-elementor-id="elementor.settings.post.id" class="elementor"
         :class="`elementor-${elementor.settings.post.id}`">
      <template v-for="(element, index) in elementor.elements" :key="element.id">
        <ElementorElement :element="element" :index="index"/>
      </template>
    </div>
  </div>
</template>